<template>
  <section class="container mt-20 mb-20 overflow-hidden" v-if="dataRelate && dataRelate.length > 0">
    <div class="flex md:flex-row flex-col gap-5 justify-between md:items-center mb-12">
      <h2 data-aos="fade-right" data-aos-once="true" class="text-black text-34px leading-42px font-[550]">
        {{ t('RELATED_NEWS') }}
      </h2>

      <nuxt-link
          :to="resCategory?.url"
          class="text-primary hover:text-accent-01 duration-300 flex items-center !lg:flex !hidden"
      >
        <span class="uppercase text-sm font-semibold leading-5"> {{ t('SHOW_MORE') }}</span>
        <span class="w-4.5 h-4.5 i-custom-chevron-right"></span>
      </nuxt-link>
    </div>
    <div
        data-aos="fade-up"
        data-aos-once="true"
        class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-8 gap-y-12"
    >
      <GlobalItemGridNew
          v-for="news in dataRelate"
          :key="news?.id"
          :dataItem="news"
          @click="trackingNews(news)"
      ></GlobalItemGridNew>
    </div>

    <nuxt-link
        :to="resCategory?.url"
        class="text-primary hover:text-accent-01 duration-300 !flex items-center !lg:hidden mt-10"
    >
      <span class="uppercase text-sm font-semibold leading-5"> {{ t('SHOW_MORE') }}</span>
      <span class="w-4.5 h-4.5 i-custom-chevron-right"></span>
    </nuxt-link>
  </section>
</template>

<script lang="ts">
export default {
  name: 'new_relate'
}
</script>

<script setup lang="ts">
import {
  NEWS_EVENT_ID,
  useEventTracking
} from "../../../../composables/ackee/event";

const {getRelatedNews} = useIZNewsWidgets()
const {getNewsDetailBySlug} = useIZNewsDetail()
const {t} = useI18n()

const route = useRoute()
const _slugNewDetail = route.params.slug
const resCategory = ref()
const newsDetail = ref()
await getNewsDetailBySlug(route.params.langCode, useSeoFields('news'), {slug: _slugNewDetail})
    .then((response) => {
      resCategory.value = response.model?.category
      newsDetail.value = response.model
    })
    .catch((error) => {
      console.error(error)
    })

const dataRelate = await getRelatedNews(newsDetail.value.id, resCategory.value?.id, route.params.langCode, {limit: 6})

const trackingNews = (dataItem: any) => {
  useEventTracking(NEWS_EVENT_ID, `${dataItem?.category?.title} - ${dataItem?.title}`)
}
</script>

<style></style>
