<template>
  <section data-aos="fade-up" data-aos-once="true" class="mt-10 lg:mt-20 container mb-5 lg:mb-10">
    <div data-aos="fade-right" data-aos-once="true" class="border-b border-bw-08 mb-6">
      <global-breadcrumb :breadcrumb="breadcrumb" isDetail></global-breadcrumb>
    </div>
    <h1
        data-aos="fade-right"
        data-aos-once="true"
        class="text-2xl lg:text-30px leading-3xl lg:leading-44px font-[550] text-bw-03 mb-1 lg:mb-6"
    >
      {{ dataRender?.title }}
    </h1>
    <div class="flex md:flex-row flex-col md:gap-4 mb-8">
      <div class="flex items-center text-bw-04 font-medium text-13px leading-6 gap-2">
        <p>{{ dataRender?.date_created }}</p>
        <div class="w-1px h-18px bg-bw-10"></div>
        <p class="flex items-center gap-1">
          <span class="i-custom-clock w-3.5 h-3.5"></span>
          <span class=""> {{ dataRender?.time_created }}</span>
        </p>
        <div class="w-1px h-18px bg-bw-10"></div>
        <p class="flex items-center gap-1">
          <span class="i-custom-tag w-3.5 h-3.5 font-semibold"></span>
          <span class="">{{ dataRender?.category?.title }}</span>
        </p>
      </div>
    </div>

    <div data-aos="zoom-in" data-aos-once="true" class="w-full aspect-3/2 md:aspect-310/176">
      <img
          :src="getThumbnail(dataRender?.thumbnail?.id, 1240, true)"
          :alt="dataRender?.thumbnail?.description ?? dataRender?.thumbnail?.title"
          class="w-full h-full object-cover rounded-15px"
      />
    </div>
  </section>
</template>

<script lang="ts">
export default {
  name: 'new_header_content'
}
</script>

<script setup lang="ts">
const {getNewsDetailBySlug} = useIZNewsDetail()

const route = useRoute()
// const _idNewDetail = route.params.id.split('-').pop()
const _slugNewDetail = route.params.slug
const res = ref()
const breadcrumb = ref<Object[]>([])

if (route.params.langCode == 'vi-VI') {
  breadcrumb.value.push({
    title: 'Tin tức',
    url: '/tin-tuc'
  })
} else {
  breadcrumb.value.push({
    title: 'News',
    url: '/en/news'
  })
}
await getNewsDetailBySlug(route.params.langCode, useSeoFields('news'), {slug: _slugNewDetail})
    .then((response) => {
      res.value = response
      breadcrumb.value.push(response?.model?.category)
      breadcrumb.value.push({
        title: response?.model?.title,
        url: response?.model?.url
      })
    })
    .catch((error) => {
      console.error(error)
    })
const dataRender = computed(() => {
  return res.value?.model
})
</script>

<style></style>
