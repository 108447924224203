<template>
  <ul class="flex flex-col gap-2.5">
    <!-- <li>
        <a
            :id="'zalo-share-button-news-detail-' + useRoute().params.slug"
            class="zalo-share-button w-9 h-9 flex justify-center items-center bg-final-02 rounded-full text-primary hover:bg-primary hover:text-white duration-300 cursor-pointer"
            data-oaid="1381434222232805321"
            data-href=""
            data-customize="true"
        >
            <span class="text-11px font-[550]">Zalo</span>
        </a>
    </li> -->
    <li>
      <a
          :href="shareLinkFacebook"
          target="_blank"
          class="w-9 h-9 flex justify-center items-center bg-final-02 rounded-full text-primary hover:bg-primary hover:text-white duration-300"
      >
        <span class="text-base font-medium i-custom-facebook2 w-4.5 h-4.5"></span>
      </a>
    </li>
    <!-- <li>
        <a
            :href="shareLinkMessenger"
            target="_blank"
            class="w-9 h-9 flex justify-center items-center bg-final-02 rounded-full text-primary hover:bg-primary hover:text-white duration-300"
        >
            <span class="text-base font-medium i-custom-messenger w-4.5 h-4.5"></span>
        </a>
    </li> -->
    <li>
      <a
          :href="shareLinkLinked"
          target="_blank"
          class="w-9 h-9 flex justify-center items-center bg-final-02 rounded-full text-primary hover:bg-primary hover:text-white duration-300"
      >
        <span class="text-base font-medium i-custom-linked w-4.5 h-4.5"></span>
      </a>
    </li>
    <li>
      <a
          :href="shareMail"
          target="_blank"
          class="w-9 h-9 flex justify-center items-center bg-final-02 rounded-full text-primary hover:bg-primary hover:text-white duration-300"
      >
        <span class="text-base font-medium i-custom-mail2 w-4.5 h-4.5"></span>
      </a>
    </li>
    <li>
      <button
          @click="printContent()"
          class="w-9 h-9 flex justify-center items-center bg-final-02 rounded-full text-primary hover:bg-primary hover:text-white duration-300 cursor-pointer"
      >
        <span class="text-base font-medium i-custom-print w-4.5 h-4.5"></span>
      </button>
    </li>
    <li>
      <button
          @click="handleCopyLink()"
          class="cursor-pointer w-9 h-9 flex justify-center items-center bg-final-02 rounded-full text-primary hover:bg-primary hover:text-white duration-300"
      >
        <span class="text-base font-medium i-custom-share w-4.5 h-4.5" v-if="!isCopiedRef"></span>
        <span class="text-base font-medium i-ic:baseline-check w-4.5 h-4.5" v-else></span>
      </button>
    </li>
  </ul>
</template>

<script setup lang="ts">
const printContent = () => {
  printJS({
    printable: 'content_new',
    type: 'html'
  })
}

const isCopiedRef = ref(false)
const shareLinkFacebook = ref()
const shareLinkLinked = ref()
const shareLinkMessenger = ref()
const shareMail = ref()
const copyToClipboard = (url: any) => {
  var sampleTextarea = document.createElement('textarea')
  document.body.appendChild(sampleTextarea)
  sampleTextarea.value = url //save main text in it
  sampleTextarea.select() //select textarea contenrs
  document.execCommand('copy')
  document.body.removeChild(sampleTextarea)
}
const href = ref()
const handleCopyLink = () => {
  copyToClipboard(href.value)
  isCopiedRef.value = true
}
onMounted(() => {
  href.value = window.location.href
  shareLinkFacebook.value = `https://www.facebook.com/sharer/sharer.php?u=${href.value} `
  shareLinkLinked.value = `https://www.linkedin.com/shareArticle?mini=true&url=${href.value}`
  // shareLinkMessenger.value = 'https://www.messenger.com/share.php?u=' + encodeURIComponent(href.value)
  shareMail.value = `mailto:email@domain.com?subject=${href.value}`

  document
      .querySelector('#zalo-share-button-news-detail-' + useRoute().params.slug)
      ?.setAttribute('data-href', href.value)
})
</script>

<style></style>
