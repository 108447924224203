<template>
  <section class="container mt-20 mb-20 overflow-hidden" v-if="dataLatest && dataLatest.length > 0">
    <div class="flex md:flex-row flex-col gap-5 justify-between md:items-center mb-12">
      <h2 data-aos="fade-right" data-aos-once="true" class="text-black text-34px leading-42px font-[550]">
        {{ t('MOST_VIEWED_ARTICLES') }}
      </h2>

      <nuxt-link :to="url" class="text-primary hover:text-accent-01 duration-300 items-center !lg:flex !hidden">
        <span class="uppercase text-sm font-semibold leading-5">{{ t('SHOW_MORE') }}</span>
        <span class="w-4.5 h-4.5 i-custom-chevron-right"></span>
      </nuxt-link>
    </div>
    <div
        data-aos="fade-up"
        data-aos-once="true"
        class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-8 gap-y-12"
    >
      <GlobalItemGridNew
          v-for="news in dataLatest"
          :key="news?.id"
          :dataItem="news"
          @click="trackingNews(news)"
      ></GlobalItemGridNew>
    </div>
    <nuxt-link :to="url" class="text-primary hover:text-accent-01 duration-300 flex items-center lg:hidden mt-10">
      <span class="uppercase text-sm font-semibold leading-5">{{ t('SHOW_MORE') }}</span>
      <span class="w-4.5 h-4.5 i-custom-chevron-right"></span>
    </nuxt-link>
  </section>
</template>

<script lang="ts">
export default {
  name: 'new_relate'
}
</script>

<script setup lang="ts">
import {NEWS_EVENT_ID, useEventTracking} from "../../../../composables/ackee/event";

const {getOnTop} = useIZNewsWidgets()
const {t} = useI18n()
const route = useRoute()

const URLs = {
  vi: '/' + t('SHOW_MORE_URL_NEWS'),
  en: '/en/' + t('SHOW_MORE_URL_NEWS')
}
const url = ref<any>(URLs[route.params.lang])

const dataLatest = ref([])
const res = await getOnTop(route.params.langCode, {query: {limit: 6}})

const trackingNews = (dataItem: any) => {
  useEventTracking(NEWS_EVENT_ID, `${dataItem?.category?.title} - ${dataItem?.title}`)
}
dataLatest.value = res
</script>

<style></style>
