<template>
  <TableOfContentToc @listHeading="checkHeading" content-selector="#content_new"/>
</template>

<script setup lang="ts">
defineProps({
  loading: {
    type: Boolean
  }
})
const emit = defineEmits(['listHeading'])
const checkHeading = (data: any) => {
  emit('listHeading', data)
}
</script>

<style></style>
